import React, { Component, useEffect, useState } from 'react';
import './NavMenu.css';
import { Link, NavLink } from 'react-router-dom';
import { Sidebar } from './Sidebar';
import { HashLink } from 'react-router-hash-link';
import Disclaimer from '../Pages/DRHP/Disclaimer';

export const NavMenu = () => {
    const [scroll, setScroll] = useState(false);
    const [openaside, setOpenAside] = useState(false);
    const [modalOpen , setModalOpen] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
        });
    }, []);

    return (
        <>
            <div id="header">
                <nav className={`navbar navbar-expand-lg navbar-light position-absolute w-100 ${scroll ? 'fixed' : ''}`} id="burger">
                    <div className="container align-items-center">
                        <Link className="navbar-brand logo-white" to="/"><img className="logo1" src="./images/logo.svg" alt="" /></Link>
                        <div className="navbar-collapse collapse" id="navbarNav">
                            <ul className="navbar-nav ms-auto text-center justify-content-center align-items-center">
                                <li className="nav-item">
                                    <NavLink to='/' className="nav-link" aria-current="page">Home</NavLink></li>
                                <li className="nav-item">
                                    <NavLink to='/aboutus' className="nav-link">About Us <i className="fa-solid fa-angle-down"></i></NavLink>
                                    <div className="dropdown_menu position-absolute">
                                        <li>
                                            <HashLink smooth to="/aboutus/#history">History</HashLink>
                                        </li>
                                        <li>
                                            <HashLink smooth to="/aboutus/#company-profile">Company Profile</HashLink>
                                        </li>
                                        <li>
                                            <Link to='/board-of-directors'>Board of Directors</Link>
                                        </li>
                                        <li>

                                            <Link to='/key-managerial-personnel'>Key Managerial Personnel</Link>
                                        </li>
                                        <li>

                                            <Link to='/senior-management'>Senior Management</Link>
                                        </li>
                                        <li>
                                        <Link to='/key-employees-of-Subsidiaries'>Key Employees of Subsidiaries</Link>

                                        </li>
                                        {/* <li>
                              <Link to='/key-highlights'>Key Highlights</Link>
                            </li>
                            <li>
                              <Link to='/revenue-breakup'>Revenue Breakup</Link>
                            </li>
                            <li>
                              <Link to='/group-companies'>Group Companies</Link>
                            </li> */}
                                        <li>
                                            <Link to='/awards-accreditations'>Awards and Accreditations</Link>
                                        </li>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/products" className="nav-link">Products <i className="fa-solid fa-angle-down"></i></NavLink>
                                    <div className="dropdown_menu position-absolute">
                                        <li>
                                            <Link to='connective-products'>Connective Products</Link>
                                        </li>
                                        <li>
                                            <Link to='mechatronics-products'>Mechatronics Products</Link>
                                        </li> 
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/clients" className="nav-link">Key Clients</NavLink>
                                    {/* <div className="dropdown_menu position-absolute">
                                        <li>
                                            <Link to='/clients'>Direct Customers</Link>
                                        </li>
                                        <li>
                                            <Link to='/clients'>OEM Customers</Link>*
                                        </li>
                                    </div> */}
                                </li>
                                <li className="nav-item">
                                    <Link to="#" className="nav-link">Business Operations <i className="fa-solid fa-angle-down"></i></Link>
                                    <div className="dropdown_menu position-absolute">
                                        <li>
                                            <Link to='/manufacturing'>Manufacturing Facilities</Link>
                                        </li>
                                        <li>
                                            <Link to='/research-and-development'>Research and Development</Link>
                                        </li>
                                        <li>
                                            <Link to='/worldwide-presence'>World Wide Presence</Link>
                                        </li>
                                    </div>
                                </li>
                                {/* <li className="nav-item">
                                    <Link className="nav-link">Sustainability <i className="fa-solid fa-angle-down"></i></Link>
                                    <div className="dropdown_menu position-absolute">
                                        <li>
                                            <Link to='/esg-profile'>ESG Profile</Link>
                                        </li>
                                        <li>
                                            <Link to='/brsr'>BRSR</Link>
                                        </li>
                                        <li>
                                            <Link to='/corporate-social-responsibilty'>CSR</Link>
                                        </li>
                                    </div>
                                </li> */}
                                {/*<li className="nav-item">*/}
                                {/*    <Link to="/careers" className="nav-link">Careers</Link>*/}
                                {/*</li>*/}
                                
                                <li className="nav-item position-relative">
                                    <Link className="nav-link">Investors  <i className="fa-solid fa-angle-down"></i></Link>
                                    <div className="dropdown_menu mega_menu position-absolute">
                                        <div className="row">
                                            <div className="col-md-6 border-end">
                                                <h6 className='fw-bold pb-1 mb-0 text-blue text-start'>Key Documents</h6>
                                                    <li>
                                                        <Link style={{whiteSpace:'wrap'}} onClick={()=> setModalOpen(!modalOpen)}>Draft Red Herring Prospectus (DRHP)</Link>
                                                    </li>
                                                   <li>
                                                   <Link target='_blank' to='/key-documents/IndustryReport(Sep 2024).pdf' >Industry Report</Link>
                                                   </li>
                                            </div>
                                            <div className="col-md-6">
                                                <li>
                                                    <Link to='/investor-relations-platform'>Investor Relations Platform</Link>
                                                </li>
                                                <li>
                                                    <Link to='/disclosure-under-regulation-46-of-SEBI-LODR'>Disclosure Under Regulation <br /> 46 of SEBI LODR</Link>
                                                </li>
                                                <li>
                                                </li>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item">
                                        <NavLink className="nav-link" to='/contact-us'>Contact Us</NavLink>
                                </li>
                            </ul>
                        </div>
                        <button className="navbar-toggle bg-transparent border-0 d-lg-none" onClick={() => setOpenAside(true)}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                    </div>
                </nav>
            </div>

            <Sidebar openaside={openaside} setOpenAside={setOpenAside} />

            <Disclaimer modalOpen={modalOpen} onClose={() => setModalOpen(false)}/>

            
        </>
    );
}
